import React from "react";
import Typed from "typed.js";
import styled from "styled-components";
import words from "../words";

const Element = styled.span`
  display: flex;
  color: white;
  text-align: left;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: 5%;
`;

class Typing extends React.Component {
  componentDidMount() {
    const options = {
      strings: words,
      smartBackspace: true,
      typeSpeed: 50,
      backSpeed: 45,
      showCursor: false,
      loop: true,
    };

    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
    this.typed.destroy();
  }

  render() {
    return (
      <>
        <Element
          ref={(el) => {
            this.el = el;
          }}
        />
      </>
    );
  }
}

export default Typing;
