import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import orca2 from "../Images/orca.png";
import "./HeaderNav.css";

function HeaderNav() {
  const [show, handleShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 140) {
        handleShow(true);
      } else handleShow(false);
    });
    return () => {
      window.removeEventListener("scroll");
    };
  }, []);

  return (
    <div className={`navbar ${show && "nav_black"}`}>
      <img className="orcaLogo" src={orca2} alt="orcaLogo" />
      <Link Link to="landing" smooth={true} duration={1200}>
        Home
      </Link>
      <Link Link to="about" smooth={true} duration={1200}>
        About
      </Link>
      <Link Link to="stacks" smooth={true} duration={1200}>
        Stack
      </Link>
      <Link Link to="work" smooth={true} duration={1200}>
        Work
      </Link>
      <Link Link to="contact" smooth={true} duration={1200}>
        Contact
      </Link>
    </div>
  );
}
export default HeaderNav;
