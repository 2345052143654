import React from "react";
import "../index.css";
import "./LandingSection.css";
import Orca from "../Images/actuallySmallerOrcaGradSm.png";
import Typing from "./Typing";
import gitIcon from "../Images/githubwhite.png";
import fbIcon from "../Images/facebook.png";
import linkedin from "../Images/linkedin.png";
import resume from "../Images/resume.png";

export default function LandingSection() {
  return (
    <section className="landingSection" id="landing">
      <img className="orca" src={Orca} alt="orca" />
      <Typing />
      <div className="icons">
        <a href="https://github.com/RSibaja88">
          <img className="icon" src={gitIcon} alt="github" />
        </a>
        <a href="https://www.facebook.com/rodrigo.sibaja.526">
          <img className="icon" src={fbIcon} alt="facebook" />
        </a>
        <a href="https://www.linkedin.com/in/rodrigo-sibaja-019479192">
          <img className="linkIcon" src={linkedin} alt="linkedin" />
        </a>
        <a href="https://docs.google.com/document/d/e/2PACX-1vRI8XPbq3imqjTG4KancqpLYIchwYXTUr3_5-Ojw_vYLghtOQeaQTPoKXGKO0Y7jKg0BrfIHeXCmBag/pub">
          <img className="iconR" src={resume} alt="resume" />
        </a>
      </div>
    </section>
  );
}
