import React from "react";
import "./Stack.css";
import stackIcons from "../Images/stackIcons.png";
import otherIcons from "../Images/otherIcons.png";

function Stack() {
  return (
    <div className="stacks" id="stacks">
      <div className="stackCols">
        <div className="stackCol">
          <h2>Stack</h2>
          <img className="stackPic" src={stackIcons} alt="stackpic" />
        </div>
        <div className="otherCol">
          <h2>Other Tech</h2>
          <img className="otherPic" src={otherIcons} alt="otherpic" />
        </div>
      </div>
    </div>
  );
}

export default Stack;
