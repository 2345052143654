const words = [
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Awesome!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Different!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Exciting!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=067AC4>Creative!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=F5B830>Clever!</font>",
  "Hi, my name is Rodrigo.<br>Let's BUILD SOMETHING:<font color=00D8FE>Fun!</font>",
];

export default words;
