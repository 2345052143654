import React from "react";
import emailjs from "emailjs-com";
import "./Contact.css";
import gitIcon from "../Images/githubwhite.png";
import fbIcon from "../Images/facebook.png";
import linkedin from "../Images/linkedin.png";
import resume from "../Images/resume.png";

export default function Contact() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "portfolio_email",
        e.target,
        "user_QsZODSaKgihJNbLbSp0XG"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <section className="contact" id="contact">
      <div className="titleContact">Contact Me</div>
      <div className="emailContainer">
        <form className="contact-form" onSubmit={sendEmail}>
          <div className="inputPanel">
            <input
              type="text"
              name="subject"
              className="subBar"
              placeholder="Subject"
            />
            {/* <label>Name: </label> */}
            <input
              type="text"
              name="name"
              className="nameBar"
              placeholder="Name"
            />
            {/* <label>Name: </label> */}

            <input
              type="email"
              name="email"
              className="emailBar"
              placeholder="Email"
            />
            {/* <label>Email: </label> */}

            <input
              type="text"
              name="company"
              className="companyBar"
              placeholder="Company"
            />
            {/* <label>Company: </label> */}

            <input
              type="text"
              name="message"
              className="textarea"
              placeholder="Message..."
            />
            {/* <label>Message: </label> */}
            {/* <textarea name="message" /> */}

            <input className="sendButton" type="submit" value="Send" />
          </div>
        </form>
      </div>
      <div className="iconsC">
        <a href="https://github.com/RSibaja88">
          <img className="iconC" src={gitIcon} alt="github" />
        </a>
        <a href="https://www.facebook.com/rodrigo.sibaja.526">
          <img className="iconC" src={fbIcon} alt="facebook" />
        </a>
        <a href="https://www.linkedin.com/in/rodrigo-sibaja-019479192/">
          <img className="iconC" src={linkedin} alt="linkedin" />
        </a>
        <a href="https://drive.google.com/file/d/1HMhShVNKj5ypD47G2bGPDWo35tGRhr3y/view?usp=sharing">
          <img className="resIcon" src={resume} alt="resume" />
        </a>
      </div>
      <footer>
        I built this site using React.js and Styled Components. Last maintained
        on August 29, 2020 - Rodrigo Sibaja
      </footer>
    </section>
  );
}
