import React from "react";
import styled from "styled-components";
import "../index.css";
import "./About.css";
import headshot from "../Images/HeadShot.png";
import signature from "../Images/thinBlackSig.png";

export default function About() {
  const BioCell = styled.div`
    margin-top: 0%;
    background-color: black;
    width: 100%;
    height: auto;
    padding-top: 7.75;
    padding-bottom: 7.75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    ${"" /* border: 5px solid hotpink; */}
  `;
  const H2 = styled.div`
    display: flex;
    padding-top: 1vw;
    padding-bottom: 0.5%;
    font-family: "LemonMilk";
    font-size: 4vw;
    color: #00d8feff;
    ${"" /* border: 3px solid yellow; */}

    @media (max-width: 768px) {
      font-size: 7vw;
      padding-top: 3vw;
      padding-bottom: 6vw;
    }
  `;
  const BioFill = styled.div`
    font-family: "Halva";
    color: white;
    padding-top: 2vh;
    padding-bottom: 1%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: right;
  `;
  const BioCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin-left: 0;
    padding-left: 0;

    @media (max-width: 768px) {
    flex-direction: column-reverse;
  `;
  const Headshot = styled.div`
    display: flex;
    justify-content: center;
    margin: 0;
    padding-top: 1%;
    padding-left: 5%;
    object-fit: contain;
    ${"" /* border: 10px solid purple */}
  `;
  const PicSect = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-top: 0%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 17.5vw;
    object-fit: contain;
    ${"" /* border: 2px solid orange; */}
    @media (max-width: 768px) {
      padding-bottom: 5vw;

  `;
  const Sig = styled.div`
    display: flex;
    justify-content: center;
    margin: 0;
    width: 11vw;
    height: 10vw;
    padding-top: 5%;
    ${"" /* border: 2px solid teal; */}

    @media (max-width: 768px) {
    width: 12vw;
    height: 22vw;
  `;

  return (
    <section className="aboutM" id="about">
      <BioCell>
        <H2>About Me</H2>
        <BioCard>
          <BioFill>
            <div className="bioP">
              Hello, I'm Rodrigo Sibaja. I'm a Full Stack JavaScript Developer with
              a Front-End focus based near Atlanta, Georgia. I've also
              worked as a Graphic Designer, designing logos and other
              marketing graphics for various companies. 
              When I'm able to incorporate design into my development projects, I thrive. 
              That's what I'm most passionate about,
              coding, design, ...and orca pool floaties.
            </div>
            <Sig>
              <img className="signature" src={signature} alt="Signature" />
            </Sig>
          </BioFill>
          <PicSect>
            <Headshot>
              <img className="headshot" src={headshot} alt="HeadShot" />
            </Headshot>
          </PicSect>
        </BioCard>
      </BioCell>
    </section>
  );
}
