import React from "react";
import styled from "styled-components";
import "./Work.css";
import celeb from "../Images/celebDOA.gif";
import conway from "../Images/conway.gif";
import sevseas from "../Images/sevenSeasSm.gif";
import resfebDemo from "../Images/resfeberDemoSm.gif";
import world from "../Images/WhereInTheWorldSmall.gif";
import flix from "../Images/netflixSmall.gif";
import anyF from "../Images/Anytime-F.gif";
import bot1 from "../Images/Bot1.png";
import bot2 from "../Images/Bot2.png";
import bot3 from "../Images/Bot3.png";
import sb1 from "../Images/SB1.png";
import sb2 from "../Images/SB2.png";
import sb3 from "../Images/SB3.png";
import bgit from "../Images/githubblack.png";

const WorkCont = styled.div`
  display: flex;
  flex-direction: column;
  ${"" /* border: 3px solid yellow; */}
`;

const DevCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(6, 122, 196);
  background: -moz-linear-gradient(
    153deg,
    rgba(6, 122, 196, 1) 26%,
    rgba(0, 216, 254, 1) 90%
  );
  background: -webkit-linear-gradient(
    153deg,
    rgba(6, 122, 196, 1) 26%,
    rgba(0, 216, 254, 1) 90%
  );
  background: linear-gradient(
    153deg,
    rgba(6, 122, 196, 1) 26%,
    rgba(0, 216, 254, 1) 90%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#067ac4",endColorstr="#00d8fe",GradientType=1);
  width: 90%;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3%;
  ${"" /* border: 3px solid pink; */}
`;

const GraphCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 216, 254, 1);
  background: -moz-linear-gradient(
    153deg,
    rgba(0, 216, 254, 1) 26%,
    rgba(6, 122, 196, 1) 90%
  );
  background: -webkit-linear-gradient(
    153deg,
    rgba(0, 216, 254, 1) 26%,
    rgba(6, 122, 196, 1) 90%
  );
  background: linear-gradient(
    153deg,
    rgba(0, 216, 254, 1) 26%,
    rgba(6, 122, 196, 1) 90%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#067ac4",endColorstr="#00d8fe",GradientType=1);
  width: 90%;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3%;
}
`;

function Work() {
  return (
    <section className="work" id="work">
      <WorkCont>
        <h2 className="title">Work</h2>
        <DevCont>
          <h2 className="devHeader">Dev Work</h2>
          <div className="headerDes">
            All the GIFs are links to the deployed project.
            <p className="headDes"> *** indicates LOGOS ARE ORIGINAL DESIGNS.</p>
          </div>
          <div className="cellCont">
          <div className="cellRightG">
              <div className="gifBoxG">
                <a
                  className="gifLinkG"
                  href="https://www.sevenseaskombucha.com/"
                >
                  <img className="gif" src={sevseas} alt="sevenseas" />
                </a>
              </div>
              <div className="projDescrip">
                <div className="bold">
                  <b className="pTitle">Seven Seas Kombucha</b>
                </div>
                <div className="tech">Wix | Velo | Javascript | Node.js | SQLite </div>
                <div className="p">
                  Seven Seas Kombucha is based out of San Diego, California. They make all beverages in house and since Covid, they've begun offering delivery for local patrons. SSK brought me on to revamp their webstore, integrate a routing API to optimize their delivery routes, and create a scheduling API. Since then, we've also launched a subscription program. The subscription feature needed heavy customization and modification with Velo before it  could work for their business model. I worked directly with the business owner, taking his vision and materializing it on screen.           
                </div>
              </div>
            </div>
            <div className="cellLeft">
              <div className="gifBox">
                <a
                  className="gifLink"
                  href="https://where-in-the-world-rssibaja.netlify.app/"
                >
                  <img className="world" src={world} alt="world" />
                </a>
              </div>
              <div className="projDescrip">
                <div className="bold">
                  <b className="pTitle">***Where in the World</b>
                  <div className="iconWork">
                    <a
                      className="gitA"
                      href="https://github.com/RSibaja88/travel-log-mern"
                    >
                      <img className="iconW" src={bgit} alt="bgit" />
                    </a>
                  </div>
                </div>
                <div className="tech">MongoDB | Node.js | Express.js | React.js | CSS </div>
                <div className="p">
                  Integrating a completely custom map from Mapbox, this app is a
                  virutal travel journal. I can add and delete entries, add a
                  picture, rating and a description as well. The entries are
                  stored in a Mongo Database using Mongoose. The API server is built using Node.js and
                  Express.js, and is deployed on Heroku. The client side is
                  built using React.js with React Hooks and is deployed on
                  Netlify.
                </div>
              </div>
            </div>
            <div className="cellRightG">
              <div className="gifBoxG">
                <a
                  className="gifLinkG"
                  href="https://conway-game-of-life-rss88.netlify.app/"
                >
                  <img className="gif" src={conway} alt="conway" />
                </a>
              </div>
              <div className="projDescrip">
                <div className="bold">
                  <b className="pTitle">***Game of Life</b>
                  <div className="iconWork">
                    <a
                      className="gitA"
                      href="https://github.com/RSibaja88/GameOfLife"
                    >
                      <img className="iconW" src={bgit} alt="bgit" />
                    </a>
                  </div>
                </div>
                <div className="tech">React.js | CSS</div>
                <div className="p">
                  A simulation of John Conway's Game of Life. It's constructed
                  as a S.P.A. in React.js. The rules of the game, along with a
                  brief background on the game itself appear before the game.
                  The game is functional. You can use a randomized pattern or
                  you can draw your own by touching the cells. You can start,
                  stop, clear, one step through the game, adjust speed, density
                  of live cells in the randomization generated, and also adjust
                  the size of the grid. This was a fun one to do and its also a
                  lot of fun to play around on.
                </div>
              </div>
            </div>
            <div className="cellLeft">
              <div className="gifBox">
                <a
                  className="gifLink"
                  href="https://resfeber-demo-rss.netlify.app"
                >
                  <img className="celeb" src={resfebDemo} alt="resfeber" />
                </a>
              </div>
              <div className="projDescrip">
                <div className="bold">
                  <b className="pTitle">resfeber</b>
                  <div className="iconWorkB">
                    <a
                      className="pBlog"
                      href="https://docs.google.com/document/d/e/2PACX-1vQSY8xWwzmz6iUMmhoemY0E5k6uye12EhzSFJQQc7Es6erphbTcdAEd-P-GzAKwhehn_7j7BKkC59ra/pub"
                    >
                      Blog
                    </a>
                  </div>
                </div>
                <div className="tech">React.js | Node.js | Express.js | Okta | CSS </div>
                <div className="p">
                  This project is still in development, the deployed project featured here is a limited "demo" version for this portfolio. Currently, it allows you to explore two pages. You can search for points of interest, and get more details on a specific location. I have also linked a Blog detailing  progress on this project. 
                </div>
              </div>
            </div>
            <div className="cellRight">
              <div className="gifBox">
                <a className="gifLink" href="https://sibaja-flix.vercel.app/">
                  <img className="gif" src={flix} alt="flix" />
                </a>
              </div>
              <div className="projDescrip">
                <div className="bold">
                  <b className="pTitle">Netflix Mock App</b>
                  <div className="iconWork">
                    <a
                      className="gitA"
                      href="https://github.com/RSibaja88/SibajaFlix"
                    >
                      <img className="iconW" src={bgit} alt="bgit" />
                    </a>
                  </div>
                </div>
                <div className="tech">React.js | CSS </div>
                <div className="p">
                  Making this mock Netflix app was really interesting. It was
                  enlightening to mimic the styling Netflix uses and see my work
                  echo their design so well. I wanted to add some functionality,
                  so adding on click trailers felt true to form. This project
                  utilizes a public API as well, and it was deployed on Vercel.
                  The user can browse through different genres and when clicked,
                  the app will pop out and start playing a trailer. To exit, the
                  user simply clicks the same movie.
                </div>
              </div>
            </div>
            <div className="cellLeft">
              <div className="gifBox">
                <a
                  className="gifLink"
                  href="https://sibaja-celeb-d-o-a.netlify.app/"
                >
                  <img className="celeb" src={celeb} alt="celeb" />
                </a>
              </div>
              <div className="projDescrip">
                <div className="bold">
                  <b className="pTitle">***Celebrity: Dead or Alive</b>
                  <div className="iconWork">
                    <a
                      className="gitA"
                      href="https://github.com/RSibaja88/CelebDOA"
                    >
                      <img className="iconW" src={bgit} alt="bgit" />
                    </a>
                  </div>
                </div>
                <div className="tech">React.js | CSS</div>
                <div className="p">
                  The game is simple, determine if the celebrity is dead or
                  alive. You will not be penalized for wrong answers, and you
                  will receive 1 point per correct answer. There is no end to
                  the game, the user can play indefinitely. This app is
                  responsive and has 3 different layouts for PC, Tablet, and
                  Mobile. This project utilizes a public API and was deployed
                  using Netlify.
                </div>
              </div>
            </div>
            <div className="cellRight">
              <div className="gifBox">
                <a
                  className="gifLink"
                  href="https://rsibaja88.github.io/Anytime-Fitness-RSS88/index.html#"
                >
                  <img className="gif" src={anyF} alt="anywhere" />
                </a>
              </div>
              <div className="projDescrip">
                <div className="bold">
                  <b className="pTitle">***Anywhere Fitness</b>
                  <div className="iconWork">
                    <a
                      className="gitA"
                      href="https://github.com/RSibaja88/Anytime-Fitness-RSS88"
                    >
                      <img className="iconW" src={bgit} alt="bgit" />
                    </a>
                  </div>
                </div>
                <div className="tech">HTML | CSS | LESS </div>
                <div className="p">
                  This was one of the first responsive web pages I built. It's
                  mostly a landing page, but the FAQ page does actually lead
                  somewhere. This is a fictional company who's imaginary mission
                  was to help people work out anywhere. The user would check on
                  the app to see what classes were offered near by or near a
                  chosen location. I wanted to include this to show my growth
                  and also to show that I can build responsive webpages using
                  media queries.
                </div>
              </div>
            </div>

          </div>
        </DevCont>
        <GraphCont>
          <h2 className="graphHeader">Graphic Work</h2>
          <div className="cellLeft">
            <div className="imgBoxA">
              <div className="imgBoxB">
                <img className="bot" src={bot1} alt="bot1" />
                <img className="bot2" src={bot2} alt="bot2" />
              </div>
              <img className="botM" src={bot3} alt="bot3" />
            </div>
            <div className="words">
              <div className="client">Southside Botz</div>
              <p>
                Logo work I did for Southside Botz in Peachtree City, GA. They
                are robots for hire. They make appearances and provide
                entertainment for corporate and private bookings. The poster was
                done at the client's request, they wanted a minimalist/art deco
                inspired poster of "Mac" the robot, one of their most popular
                robot personas!
              </p>
            </div>
          </div>
          <div className="cellRight">
            <div className="imgBoxC">
              <div className="imgBoxD">
                <img className="sb1" src={sb1} alt="sb1" />
                <img className="sb1" src={sb2} alt="sb2" />
              </div>

              <img className="sbB" src={sb3} alt="sb3" />
            </div>
            <div className="wordsB">
              <div className="client">Saylem's Broom</div>
              <p>
                Saylem's Broom is a family owned and operated house cleaning
                service in Brevard, FL. They are AHCA Covid-19 Certified as well
                as AHCA Professional House Cleaner Certified.
              </p>
            </div>
          </div>
        </GraphCont>
      </WorkCont>
    </section>
  );
}

export default Work;
