import React from "react";
import "./App.css";
import LandingSection from "./Components/LandingSection";
import About from "./Components/About";
import HeaderNav from "./Header/HeaderNav";
import Stack from "./Components/Stack";
import Work from "./Components/Work";
import Contact from "./Components/Contact";

export default function App() {
  return (
    <main>
      <HeaderNav />
      <LandingSection />
      <About />
      <Stack />
      <Work />
      <Contact />
    </main>
  );
}
